import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

import VendingMachine from './VendingMachine';

import QRCode from 'react-qr-code';
import Axios from 'axios';

function sendMessage(ws, message) {
  ws.send(JSON.stringify(message));
}

function generateQrCode(vendingMachineId) {
  return `https://para-ti.be/?{"action":"sale","target":"${vendingMachineId}"}`;
}

class App extends Component {
  state = {
    username: '',
    loginToken: '',
    apiToken: null,
    authenticated: false,
    slots: [],
    doorOpen: false,
    inSale: false
  };

  config = window.config;

  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.authenticateUser = this.authenticateUser.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    console.log(name, value);

    this.setState({
      [name]: value
    });
  }

  authenticateUser(event) {
    Axios.post(
      `${this.config.apiUrl}/login_token_auth/`,
      { 
        email: this.state.email, 
        token: this.state.loginToken 
      }
    )
    .then(response => this.setState({ apiToken: response.data['token'] }))
    .then(() => this.authenticateVendingMachine())
    .catch((error) => alert(error));

    event.preventDefault();
  }
  
  authenticateVendingMachine() {
    console.log(`Connecting to websocket url ${this.config.websocketUrl}...`);
    this.ws = new WebSocket(this.config.websocketUrl);

    this.ws.onopen = () => {
      console.log(`Websocket connected to ${this.config.websocketUrl}`);

      sendMessage(this.ws, {
          message: "authentication",
          token: this.state.apiToken,
          vendingmachine_id: this.config.vendingMachineId
      });

      this.getVendingMachineInfo();
    }

    this.ws.onmessage = event => {
      this.handleMessage(event.data);
    }
  }

  handleMessage(rawMessage) {
    const message = JSON.parse(rawMessage);

    switch (message.message) {
        case "authentication_succesful":
            console.log('Authenticated succesfully');
            this.setState({ authenticated: true });
            break;
        case "start_sale":
            console.log('Starting sale...');
            this.startSale();
            break;
        case "override_lock_open":
            console.log('Overriding lock open');
            break;
        case "override_lock_closed":
            console.log('Overriding lock closed');
            break;
        default:
            console.log(`Got unknown message of type ${message.message}: '${rawMessage}'`);
            alert(`Got unknown message of type ${message.message}: '${rawMessage}'`);
    }
  }

  async getVendingMachineInfo() {

      const response = await Axios.get(
          `${this.config.apiUrl}/vending_machine/${this.config.vendingMachineId}/`,
          {
            headers: {
              'Authorization': `Token ${this.state.apiToken}`
            }
          }
      );

      const { slots } = response.data;

      const slotInfo = [];

      for (let slot of slots) {
        const slotData = await this.getProductSlotInfo(slot);
        slotData.item = await this.getItemInfo(slotData.item_id);
        
        slotInfo.push(slotData);
      }

      this.setState({
        slots: slotInfo
      });

      console.log(slotInfo);
  }

  async getProductSlotInfo(slot) {
    const response = await Axios.get(
      `${this.config.apiUrl}/product_slots/${this.config.vendingMachineId}/${slot}`,
      {
        headers: {
          'Authorization': `Token ${this.state.apiToken}`
        }
      }
    );

    return response.data;
  }

  async getItemInfo(item_id) {
    // const response = await Axios.get(
    //   `${this.config.apiUrl}/items/${item}`,
    //   {
    //     headers: {
    //       'Authorization': `Token ${this.state.apiToken}`
    //     }
    //   }
    // );

    // return response.data;
    return Promise.resolve(this.config.items.find(item => item.id === item_id));
  }

  startSale() {
      if (this.state.inSale) {
          console.log('Cannot start sale: already in sale');
          sendMessage(this.ws, {
              message: "error",
              error: "already-in-sale"
          });
      }
      
      sendMessage(this.ws, { message: "success" });

      console.log('Sale started');
      return this.setState({ inSale: true, doorOpen: true });
  }

  async closeDoor(picks) {
    this.setState({ doorOpen: false });

    sendMessage(this.ws, { message: "finish_sale" });

    for (let pick of picks) {
      await Axios.post(
        `${this.config.apiUrl}/picks/`,
        pick,
        {
          headers: {
            'Authorization': `Token ${this.state.apiToken}`,
            'Content-Type': 'application/json'
          }
        }
      );
    }

    this.setState({ inSale: false });
  }

  render() {
    const header = 
      <div>
        Door state: {this.state.doorOpen ? "OPEN" : "CLOSED"},
        In sale: {this.state.inSale ? "YES" : "NO"}
      </div>;

    if (!this.state.apiToken) {
      return <form onSubmit={this.authenticateUser}>
        <p>Please authenticate:</p>
        <p><label>Email: </label><input type="text" name="email" value={this.state.email} onChange={this.handleInputChange} /></p>
        <p><label>Token: </label><input type="text" name="loginToken" value={this.state.loginToken} onChange={this.handleInputChange} /></p>
        <p><input type="submit" onClick={this.authenticateUser} /></p>
      </form>
    } else if (this.state.authenticated) {
      if (this.state.inSale) {
        return <div>
            {header}
            <VendingMachine data-id={this.config.vendingMachineId} slots={this.state.slots} onCloseDoor={this.closeDoor.bind(this)} />
          </div>;
      } else {
        return ( 
          <div>
            {header}
            <div className="container">
              <p>Scan QR code to start sale</p>
              <QRCode value={generateQrCode(this.config.vendingMachineId)} />
            </div>
          </div>
        );
      }
    } else {
      return "<p>Authenticating...</p>";
    }
  }
}

export default App;
