import React, { Component } from "react";

class VendingMachine extends Component {

    state = { };

    static getDerivedStateFromProps(nextProps, prevState) {
        if ((nextProps.slots && nextProps.slots.length) !== (prevState.picks && prevState.picks.length)) {
            return { picks: nextProps.slots.map(slot => ({ amount: 0 })) }
        }

        return null;
    }

    render() {
        const { slots } = this.props;
        const rows = slots.map((slot, i) =>
            <tr key={slot.internal_code}>
                <td>{slot.internal_code}</td>
                <td>{slot.item.name}</td>
                <td>{slot.item.price}</td>
                <td>
                    <button onClick={() => this.unpick(i)}>-</button>
                    <label>{this.state.picks[i].amount}</label>
                    <button onClick={() => this.pick(i)}>+</button>
                </td>
            </tr>
        );

        return (
            <div className="vending-machine">
                <table>
                    <thead>
                        <tr>
                            <th>Slot</th>
                            <th>Product</th>
                            <th>Price</th>
                            <th>Buy</th>
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </table>
                <button onClick={() => this.closeDoor() }> Close door</button>
            </div>
        );
    }

    unpick(index) {
        this.setState({
            picks: this.state.picks.map((pick, i) => i === index ? 
                { ...pick, amount: pick.amount - 1 } : pick)
        });
    }

    pick(index) {
        this.setState({
            picks: this.state.picks.map((pick, i) => i === index ? 
                { ...pick, amount: pick.amount + 1 } : pick)
        });
    }

    closeDoor() {
        const slots = this.props.slots;

        const picks = this.state.picks.map((pick, i) => ({
            vending_machine: this.props['data-id'],
            internal_code: slots[i].internal_code,
            weight_before: Math.max(0, pick.amount * slots[i].item.net_weight),
            weight_after: Math.min(0, pick.amount * slots[i].item.net_weight)
        }));

        this.props.onCloseDoor(picks);
    }

}

export default VendingMachine;